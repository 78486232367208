import '../parts/bootstrap'

(() => {
    const auth_page = document.querySelector("#auth-page")
    if (auth_page === null) return

    // Countdown timer
    (() => {
        const countdown = auth_page.querySelector("#countdown")
        if (countdown === null) return
        let from = countdown.dataset.hasOwnProperty("from") ? countdown.dataset.from : 5

        const countdownInterval = setInterval(() => {
            from--

            if (from === 0) {
                clearInterval(countdownInterval)
                countdown.innerHTML = "0"
                const countdownParent = countdown.parentElement
                if (countdownParent !== null) {
                    countdownParent.innerHTML = countdownParent.dataset.hasOwnProperty("countdownCompleteText")
                        ? countdownParent.dataset.countdownCompleteText
                        : "Redirecționare..."
                    window.location.href = countdownParent.href
                }
            } else {
                countdown.innerHTML = from
            }
        }, 1000)
    })()

    const auth_page_form = auth_page.querySelector("#auth-page form")
    if (auth_page_form === null) return

    // Remove filled class from all filled divs that were set by PHP
    window.onload = () => {
        const filledDivs = document.querySelectorAll(".filled")
        filledDivs.forEach(div => {
            div.classList.remove("filled")
        })
    }

    // Form input label animation like Google login page
    const inputs = document.querySelectorAll('input[name="email"], input[name="name"]')

    inputs.forEach(input => {
        (() => {
            if (input.value.trim() !== "") {
                input.classList.add("has-text")
            }
        })()

        input.addEventListener("input", () => {
            if (input.value.trim() !== "") {
                input.classList.add("has-text")
            } else {
                input.classList.remove("has-text")
            }
        })

        input.addEventListener("blur", () => {
            if (input.value.trim() === "") {
                input.classList.remove("has-text")
            }
            input.classList.remove("clicked")
        })

        input.addEventListener("click", () => {
            input.classList.add("clicked")
        })

        const label = document.querySelector(`label[for="${input.id}"]`)
        if (label !== null) {
            label.addEventListener("click", () => {
                input.classList.add("clicked")
            })
        }
    })
})()
